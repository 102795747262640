table.markdown-table {
    table-layout: fixed;
}

.markdown-page img {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

#about-data-portal img {
    width: 130px;
}