
div.dt-buttons {
    margin-top: 0.25em;
}

div.copyright {
    margin-top: 0.75em;
}

#result {
    width: 100% !important;
}

.result-info p {
    color: grey;
    margin-bottom: 0;
}