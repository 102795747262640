.beebiome-hero {
  text-align: center;
  background-color: #36260D;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.beebiome-logo {
  height: 20vmin;
}

.beebiome-link {
  color: #F5AC3A;
}
