#beebiome-footer {
    min-height: 30px;
    width: 100%;
    font-size: 0.8em;
}

#beebiome-footer p {
    color: #f7f8fa;
    text-decoration: none;
    margin-bottom: 0;
}

#beebiome-footer p:hover {
    text-decoration: underline
}

#beebiome-footer img {
    padding-bottom: 2px
}
